/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";

.header-grid {
  grid-area: header-center;
  display: grid;
  grid-template-areas:
    " . .         iah-logo  iah-logo  iah-logo  . "
    " . iah-title iah-title iah-title iah-title . ";
  grid-template-rows: minmax(120px, 160px) minmax(60px, 80px);
  grid-template-columns: minmax(0, 40px) repeat(4, 1fr) minmax(0, 40px);
  justify-items: center;
  align-content: end;
  column-gap: 6px;
  padding: 10px 0px 0px 0px;
  margin: 0px 20px 0px 20px;
  background: $light-gray5;
  box-shadow: $pt-input-box-shadow;
    &.dark{
      box-shadow: $pt-dark-input-box-shadow
  };
  border-radius: 20pt;
}

.iah-logo {
  grid-area: iah-logo;
  height: 100%;
  width: 100%;
}

.iah-title {
  grid-area: iah-title;
  height: 100%;
  width: 100%;
}

@media (min-width: 600px) {
  .header-grid {
    grid-template-areas:
      " . iah-logo  .          .  "
      " . iah-logo  iah-title  .  "
      " . iah-logo  .          .  ";
    grid-template-rows: 40px 100px 20px;
    grid-template-columns:
      20px
      minmax(140px, 180px)
      minmax(240px, 580px)
      minmax(60px, auto);
    justify-items: start;
    align-items: end;
  }
}
