/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";

.footer-grid {
  display: grid;
  justify-items: center;
  align-items: start;
}

.footer-text {
  margin: 100px;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: 1.5em;
  color: $dark-gray4;
  &.dark {
    color: $light-gray3;
  }
}
