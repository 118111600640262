/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";

.report-grid {
  display: grid;
//  height: 100%;			
  grid-template-areas:
    " report-nav-main "
    " report-title-main "
    " report-page-main "
    " report-footer-main ";
  grid-template-rows: 50px 50px minmax(200px, auto) minmax(200px, 600px);
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.report-nav-general {
  display: inline-block;
  overflow: hidden;  
  background: $dark-gray5;
}
.report-nav-main {
  grid-area: report-nav-main;
}
.report-nav-bumper-left {
  grid-area: report-nav-bumper-left;
}
.report-nav-bumper-right {
  grid-area: report-nav-bumper-right;
}

.report-title-general {
  display: inline-block;
  background: $light-gray3;
}
.report-title-main {
  grid-area: report-title-main;
  display: grid;
  justify-items: center;
  align-items: center;
  //height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.report-title-bumper-left {
  grid-area: report-title-bumper-left;
}
.report-title-bumper-right {
  grid-area: report-title-bumper-right;
}

.report-page-general {

  background: $light-gray5;
}
.report-page-main {
  grid-area: report-page-main;
  height: 100%;
  width: 100%;
  padding: 30px;
  margin: 0 ;
  overflow: hidden;
}
.report-page-bumper-left {
  grid-area: report-page-bumper-left;
}
.report-page-bumper-right {
  grid-area: report-page-bumper-right;
}


.report-footer-general {
  display: inline-block;
}
.report-footer-main {
  grid-area: report-footer-main;
}
.report-footer-bumper-left {
  grid-area: report-footer-bumper-left;
}
.report-footer-bumper-right {
  grid-area: report-footer-bumper-right;
}



.report-table{
  width: 100hw;
}

.report-center-grid {
    display: grid;
    justify-content: center;
    align-content: center;
}

.report-card {
    display: grid;
    grid-template-areas:
      " report-img "
      " report-img-title ";
    grid-template-rows: 1fr 20px;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    border-radius: 30px;
}
.report-img {
    grid-area: report-img;
    border-radius: 30px;
}

.report-img-title {
    grid-area: report-img-title;
    justify-self: center;
}


th {
    white-space: nowrap;
}



@media (min-width: 400px) {
  .report-grid {
      grid-template-areas:
        " report-nav-main           report-nav-main    report-nav-main "
     	" report-title-bumper-left  report-title-main  report-title-bumper-right "
        " report-page-bumper-left   report-page-main   report-page-bumper-right "
        " report-footer-bumper-left report-footer-main report-footer-bumper-right ";
    grid-template-columns:
      minmax(20px, auto)
      minmax(200px, 1000px)
      minmax(20px, auto);
  }

  .report-table{
  width: 70hw;
  }
  
}




