/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";

.portfolio-grid {
  display: grid;		
  grid-template-areas:
    " portfolio-nav-main "
    " portfolio-project-main ";
  grid-template-rows: 50px minmax(200px, auto);
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.portfolio-nav-general {
  background: $dark-gray5;
}
.portfolio-nav-main {
  grid-area: portfolio-nav-main;
}
.portfolio-nav-bumper-left {
  grid-area: portfolio-nav-bumper-left;
}
.portfolio-nav-bumper-right {
  grid-area: portfolio-nav-bumper-right;
}

.portfolio-project-general {
  background: $light-gray5;
}
.portfolio-project-main {
  grid-area: portfolio-project-main;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0;
  border-radius: 10px;
  border: 4px solid $dark-gray2;
  background: $light-gray2;
}
.portfolio-project-bumper-left {
  grid-area: portfolio-project-bumper-left;
}
.portfolio-project-bumper-right {
  grid-area: portfolio-project-bumper-right;
}


.display-svg {
  height: 100%;
  width: 100%;
  background: var(--iah-green);
}

@media (min-width: 600px) {
  .portfolio-grid {
      grid-template-areas:
	" portfolio-nav-main            portfolio-nav-main     portfolio-nav-main "
        " portfolio-project-bumper-left portfolio-project-main portfolio-project-bumper-right ";
    grid-template-columns:
      minmax(20px, auto)
      minmax(200px, 2000px)
      minmax(20px, auto);
  }
}
