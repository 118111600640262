/* Font import */
@import url('https://fonts.googleapis.com/css?family=Raleway:200,500|Roboto+Mono:300i,400&display=swap');

/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../node_modules/@blueprintjs/core/lib/scss/variables";


* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  padding: 0;
  margin: 0;
  background: $light-gray5;
}



.bp-app-background {
    background: $light-gray5;
  &.dark {
      background:  $dark-gray4;
  }
}

.bp-content {
    background: $white;
    &.dark {
      background: $dark-gray3;
  }
}

.bp-button {
    background: $light-gray5;
    &.dark {
      background: $dark-gray5;
  }
}

.bp-panel {
    background: $light-gray4;
    &.dark {
      background: $dark-gray5;
  }
}


.test {
  width: 100%;
  height: 100%;
  background: $red4;
}

.test-2 {
    display: flex;
    align-self: stretch;
    height: 50vh;
    margin: 30px;
    background: #D99E0B;

}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid $black;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
}

.caption-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 2em;
}

.caption-description {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: 1em;
}


.text-media-hide {
    display: none;
}

@media (min-width: 800px) {
  .text-media-hide {
  display: inline;
  }
}

.div-media-hide {
    display: none;
}
@media (min-width: 500px) {
  .div-media-hide {
  display: inline-block;
  }
}



.card-grid {
  display: grid;
  grid-template-areas:
    " card-title "
    " card-img "
    " card-code ";
  grid-template-rows: 40px 1fr 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-gap: 20px;
  padding: 20px;
  overflow: hidden;

}

.card-title {
    grid-area: card-title;
      display: inline;
}
.card-img {
    grid-area: card-img;
      display: inline;
}
.card-code {
    grid-area: card-code;
      display: inline;
}
