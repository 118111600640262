/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../../node_modules/@blueprintjs/core/lib/scss/variables";

.project-04-grid {
  display: grid;
  height: 100%;
  justify-content: center;
  align-content: start;
  grid-template-areas:
    " project-04-title "
    " project-04-display "
    " project-04-code "
    " project-04-ui " 
    " project-05-console ";
  grid-template-rows:  60px 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}


.project-04-title {
  grid-area: project-04-title;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 5px;
}

.project-04-display {
  grid-area: project-04-display;
  display: grid;
  justify-content: center;
  align-content: center;
  margin: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.project-04-code {
  grid-area: project-04-code;
  border-radius: 5px;
}


.project-04-ui {
  grid-area: project-04-ui;
  border: 1px solid $black;
  background: $pt-intent-danger;
  border-radius: 5px;
}


.project-04-console {
  grid-area: project-04-console;
  border: 1px solid $black;
  border-radius: 5px;
}


@media (min-width: 600px) {
  .project-04-grid {
    grid-template-areas:
      "  project-04-title   project-04-title   project-04-title   " 
      "  project-04-display project-04-display project-04-display " 
      "  project-04-code    project-04-code    project-04-ui    " 
      "  project-04-console project-04-console project-04-console ";
    grid-template-rows: 60px minmax(600px, auto) 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;

  }
}

