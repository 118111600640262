/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";

.user-grid {
  display: grid;
//  height: 100%;			
  grid-template-areas:
    " user-nav-main "
    " user-title-main "
    " user-controls-main "
    " user-form-main ";
  grid-template-rows: 50px 50px minmax(200px, auto) minmax(200px, auto);
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.user-nav-general {
  display: inline-block;
  background: $dark-gray5;
}
.user-nav-main {
  grid-area: user-nav-main;
}
.user-nav-bumper-left {
  grid-area: user-nav-bumper-left;
}
.user-nav-bumper-right {
  grid-area: user-nav-bumper-right;
}

.user-title-general {
  display: inline-block;
  background: $light-gray3;
}
.user-title-main {
  grid-area: user-title-main;
  height: 100%;
  width: 100%;
  padding: 0px;
}
.user-title-bumper-left {
  grid-area: user-title-bumper-left;
}
.user-title-bumper-right {
  grid-area: user-title-bumper-right;
}

.user-controls-general {
  display: inline-block;
  background: $light-gray5;
}
.user-controls-main {
  grid-area: user-controls-main;
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0 ;
  border-radius: 10px;
  border: 4px solid $dark-gray2;
  background: $light-gray2;
}
.user-controls-bumper-left {
  grid-area: user-controls-bumper-left;
}
.user-controls-bumper-right {
  grid-area: user-controls-bumper-right;
}


.user-form-general {
  display: inline-block;
  background: $light-gray5;
}
.user-form-main {
  grid-area: user-form-main;
  width: 100%;
  padding: 20px;
  margin: 10px 0 0 0 ;
  border-radius: 10px;
  border: 4px solid $dark-gray2;
  background: $light-gray2;
}
.user-form-bumper-left {
  grid-area: user-form-bumper-left;
}
.user-form-bumper-right {
  grid-area: user-form-bumper-right;
}


@media (min-width: 600px) {
  .user-grid {
      grid-template-areas:
        " user-nav-main             user-nav-main      user-nav-main "
     	" user-title-bumper-left    user-title-main    user-title-bumper-right "
        " user-controls-bumper-left user-controls-main user-controls-bumper-right "
        " user-form-bumper-left     user-form-main     user-form-bumper-right ";
    grid-template-columns:
      minmax(20px, auto)
      minmax(200px, 2000px)
      minmax(20px, auto);
  }

}
