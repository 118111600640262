/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";


.main-grid {
  display: grid;
  grid-template-areas:
    " top-bar-main "
    " header-main "
    " med-bar-main "
    " content-main "
    " footer-main ";
  grid-template-rows: 10px 300px auto minmax(200px, auto) auto;
  grid-template-columns: 1fr;
}

.top-bar-general {
  display: inline-block;
}
.top-bar-main {
  grid-area: top-bar-main;
}
.top-bar-bumper-left {
  grid-area: top-bar-bumper-left;
}
.top-bar-bumper-right {
  grid-area: top-bar-bumper-right;
}

.header-general {
  display: inline-block;
}
.header-main {
  grid-area: header-main;
}
.header-bumper-left {
  grid-area: header-bumper-left;
}

.header-bumper-right {
  grid-area: header-bumper-right;
}

.med-bar-general {
  display: inline-block;
}
.med-bar-main {
  grid-area: med-bar-main;
}
.med-bar-bumper-left {
  grid-area: med-bar-bumper-left;
}
.med-bar-bumper-right {
  grid-area: med-bar-bumper-right;
}

.content-general {
    display: inline-block;
}
.content-main {
  grid-area: content-main;
}
.content-bumper-left {
  grid-area: content-bumper-left;
}
.content-bumper-right {
  grid-area: content-bumper-right;
}

.footer-general {
  display: inline-block;
}
.footer-main {
  grid-area: footer-main;
}
.footer-bumper-left {
  grid-area: footer-bumper-left;
}
.footer-bumper-right {
  grid-area: footer-bumper-right;
}

@media (min-width: 600px) {
  .main-grid {
    grid-template-areas:
      " top-bar-bumper-left top-bar-main top-bar-bumper-right "
      " header-bumper-left  header-main  header-bumper-right "
      " med-bar-main        med-bar-main med-bar-main "
      " content-bumper-left content-main content-bumper-right "
      " footer-bumper-left  footer-main  footer-bumper-right ";
    grid-template-rows: 20px 200px auto minmax(200px, auto) minmax(200px, 1000px);
    grid-template-columns:
      minmax(20px, auto)
      minmax(200px, 1000px)
      minmax(20px, auto);
  }
}


.main-center-grid {
    display: grid;
    width: 100%;
    justify-content: center;
}

.main-content-card-grid {
    display: grid;
    grid-template-areas:
      " main-content-card-title "
      " main-content-card-img "
      " main-content-card-code ";
    grid-template-rows: 40px 2fr auto;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    justify-content: right;
    border-radius: 30px;
    padding: 20px;
    margin: 40px;
    overflow: hidden;
} 
.main-content-card-img {
    grid-area: main-content-card-img;
    border-radius: 30px;
}

.main-content-card-title {
    grid-area: main-content-card-title;

}
.main-content-card-code {
    grid-area: main-content-card-code;
    border-radius: 30px;
}


.bp-card-background {
    background: $light-gray4;
    &.dark {
      background: $dark-gray5;
  }
}
