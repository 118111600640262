/* IAH Color Variables */

:root {
  --iah-red: #b84547;
  --iah-red-light: #c28384;
  --iah-yellow: #e7ea81;
  --iah-green: #a5d27c;
  --iah-blue: #2a8bb9;
  --iah-light: #f1f8ed;
  --iah-grey-light: #dad9d9;
  --iah-grey-med: #a6a5a5;
  --iah-grey-dark: #383838;

  --cs1-grey-dark: #383838;
  --cs1-yellow-dark: #6b6b5c;
  --cs1-red-light: #c28384;
  --cs1-red: #b84547;
  --cs1-yellow: #e7ea81;
  --cs1-yellow-light: #ededc9;

  --cs2-grey-dark: #383838;
  --cs2-green-dark: #739156;
  --cs2-green: #a5d27c;
  --cs2-yellow: #e7ea81;
  --cs2-yellow-light: #ededc9;
  --cs2-light: #f1f8ed;

  --cs3-grey-dark: #383838;
  --cs3-yellow-dark: #6b6b5c;
  --cs3-blue: #2a8bb9;
  --cs3-yellow: #e7ea81;
  --cs3-yellow-light: #ededc9;
  --cs3-light: #f1f8ed;

  --cs4-grey-dark: #383838;
  --cs4-orange: #ea703e;
  --cs4-red: #b84547;
  --cs4-blue: #2a8bb9;
  --cs4-blue-light: #62c5f0;
  --cs4-light: #f1f8ed;

  --cs5-grey-dark: #383838;
  --cs5-green-dark: #739156;
  --cs5-green: #a5d27c;
  --cs54-blue: #2a8bb9;
  --cs5-purple: #97a1d1;
  --cs5-light: #f1f8ed;
}

.iah-text-Raleway {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.iah-text-black {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 2em;
  overflow-wrap: break-word;
  margin: 10px;
  color: var(--iah-grey-dark);
}

.iah-text-code {
  font-family: "Inconsolata", monospace;
  font-weight: 100;
  margin: 5px;
  /* font-size: 1.5em;
     color: var(--iah-grey-dark); */
}

.iah-li {
  display: block;
  padding: 4px 10px 4px 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  background: var(--iah-grey-dark);
  border-radius: 5px;
}

.iah-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

.iah-links,
.iah-link {
  text-decoration: none;
  list-style-type: none;
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  font-weight: 300;
  font-size: 1em;
  color: var(--iah-light);
}

.iah-select {
  margin: 20px;
  text-decoration: none;
  list-style-type: none;
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  font-weight: 300;
  font-size: 0.7em;
  color: var(--iah-dark);
}

.iah-svg {
  display: block;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media (min-width: 600px) {
  .iah-links {
    height: 200px;
    flex-direction: column;
    padding: 10px;
  }
}
