/* Blueprint colors https://blueprintjs.com/docs/#core/colors */
@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";

.code-grid {
  display: grid;
//  height: 100%;			
  grid-template-areas:
    " code-nav-main "
    " code-title-main "
    " code-controls-main "
    " code-form-main ";
  grid-template-rows: 50px 50px minmax(200px, auto) minmax(200px, auto);
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.code-nav-general {
  display: inline-block;
  background: $dark-gray5;
}
.code-nav-main {
  grid-area: code-nav-main;
}
.code-nav-bumper-left {
  grid-area: code-nav-bumper-left;
}
.code-nav-bumper-right {
  grid-area: code-nav-bumper-right;
}

.code-title-general {
  display: inline-block;
  background: $light-gray3;
}
.code-title-main {
  grid-area: code-title-main;
  height: 100%;
  width: 100%;
  padding: 0px;
}
.code-title-bumper-left {
  grid-area: code-title-bumper-left;
}
.code-title-bumper-right {
  grid-area: code-title-bumper-right;
}

.code-controls-general {
  display: inline-block;
  background: $light-gray5;
}
.code-controls-main {
  grid-area: code-controls-main;
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0 ;
  border-radius: 10px;
  border: 4px solid $dark-gray2;
  background: $light-gray2;
}
.code-controls-bumper-left {
  grid-area: code-controls-bumper-left;
}
.code-controls-bumper-right {
  grid-area: code-controls-bumper-right;
}


.code-form-general {
  display: inline-block;
  background: $light-gray5;
}
.code-form-main {
  grid-area: code-form-main;
  width: 100%;
  padding: 20px;
  margin: 10px 0 0 0 ;
  border-radius: 10px;
  border: 4px solid $dark-gray2;
  background: $light-gray2;
}
.code-form-bumper-left {
  grid-area: code-form-bumper-left;
}
.code-form-bumper-right {
  grid-area: code-form-bumper-right;
}


@media (min-width: 600px) {
  .code-grid {
      grid-template-areas:
        " code-nav-main             code-nav-main      code-nav-main "
     	" code-title-bumper-left    code-title-main    code-title-bumper-right "
        " code-controls-bumper-left code-controls-main code-controls-bumper-right "
        " code-form-bumper-left     code-form-main     code-form-bumper-right ";
    grid-template-columns:
      minmax(20px, auto)
      minmax(200px, 2000px)
      minmax(20px, auto);
  }

}
